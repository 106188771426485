import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AvisoLegal = () => (
  <Layout>
    <SEO title="Aviso Legal" />

    <div className="page-legal container">
    	<h1 className="main-title">Aviso Legal</h1>

		<h2 className="title">Propiedad del sitio web</h2>
		<p>Con el fin de dar cumplimiento al artículo 10 de la Ley 34/2002 de Servicios de la Sociedad de la Información y del Comercio Electrónico, informamos a los usuarios de nuestros datos:</p>
		
		<ul>
			<li>Titular del sitio: Anna Ferry Mestres</li>
			<li>Domicilio Social: Segre 76 A Ent 2, 08030 Barcelona (Barcelona)</li>
			<li>NIF/CIF: 47648875M</li>
			<li>Teléfono: 933 463 821</li>
			<li>E-Mail: info@cocowat.com</li>
			<li>Sitio Web: www.cocowat.com</li>
		</ul>

		<h2 className="title">Condiciones generales de uso</h2>
		<p>El portal web https://www.cocowat.com tiene como objeto facilitar al público, en general, el conocimiento de las actividades que esta organización realiza y de los productos y servicios que presta, 
		para el desarrollo de su actividad.</p> 


		<p>El uso de cualquiera de las funcionalidades del sitio web implica la expresa y plena aceptación de las condiciones aquí expuestas, sin perjuicio de aquellas particulares que 
		pudieran aplicarse a algunos de los servicios concretos ofrecidos a través del sitio web.</p>

		<p>El titular del sitio web se reserva la facultad de efectuar, en cualquier momento y sin necesidad de previo aviso, modificaciones y actualizaciones de la información contenida 
		en su web o en la configuración y presentación de ésta.</p> 
		<p>Con el objeto de mantener actualizada la información publicada el portal web, los contenidos del mismo podrán ser modificados, corregidos, eliminados o añadidos en cualquier momento, 
		por lo que será conveniente comprobar la vigencia o exactitud de los mismos acudiendo a las fuentes oficiales.</p>

		<h2 className="title">Propiedad intelectual e industrial</h2>
		<p>Todos los elementos que forman el sitio web, así como su estructura, diseño, código fuente, así como los logos, marcas y demás signos distintivos que aparecen en la misma, son titularidad 
		de Anna Ferry Mestres, COCOWAT o de sus colaboradores y están protegidos por los correspondientes derechos de propiedad intelectual e industrial. 
		Igualmente están protegidos por los correspondientes derechos de propiedad intelectual e industrial las imágenes y otros elementos gráficos contenidos en el sitio web.</p>

		<p>El titular del sitio web prohíbe expresamente la realización de "framing" o la utilización por parte de terceros de cualesquiera otros mecanismos que alteren el diseño, 
		configuración original o contenidos de nuestro sitio web.</p>

		<p>El uso de los contenidos deberá respetar su licenciamiento particular. De tal modo su uso, reproducción, distribución, comunicación pública, transformación o cualquier otra actividad similar 
		o análoga, queda totalmente prohibida salvo que medie previa y expresa autorización del titular del sitio Web COCOWAT.</p>

		<p>Únicamente se autoriza la reproducción total o parcial de los textos y contenidos proporcionados por el sitio web, siempre que concurran todas y cada una de las siguientes condiciones:</p>

		<ul>
				<li>Se mantenga la integridad de los contenidos, documentos o gráficos.</li>
				<li>Se cite expresamente al titular del sitio web como fuente y origen de aquellos.</li>
				<li>El propósito y la finalidad de tal uso sea compatible con los fines de la web y/o actividad del titular del sitio web.</li>
				<li>No se pretenda un uso comercial, quedando expresamente prohibidas su distribución, comunicación pública, transformación o descompilación.</li>
		</ul>

		<p>Cualquier otro uso habrá de ser comunicado y autorizado por el titular del sitio web, previa y expresamente. Respecto a las citas de productos y servicios de terceros, 
		el titular del sitio web reconoce a favor de sus titulares los correspondientes derechos de propiedad industrial e intelectual, no implicando su sola mención o aparición 
		en la web la existencia de derechos ni de responsabilidad alguna sobre los mismos,como tampoco respaldo, patrocinio o recomendación. </p>

		<p>El titular del sitio web declara su respeto a los derechos de propiedad intelectual e industrial de terceros; por ello, si considera que nuestro sitio web pudieran 
		estar violando sus derechos, rogamos se ponga en contacto con el titular del sitio web.</p>

		<h2 className="title">Responsabilidad</h2>

		<p>El titular del sitio web no garantiza la inexistencia de errores en el acceso a la web, en su contenido, ni que éste se encuentre actualizado, aunque el titular
		 del sitio web desarrollará sus mejores esfuerzos para, en su caso, evitarlos, subsanarlos o actualizarlos.Tanto el acceso al sitio web, como el uso que pueda hacerse de la información 
		 contenida en los mismoses de la exclusiva responsabilidad de quien lo realiza.</p>

		<p>El titular del sitio web no se hace responsable de la información y contenidos almacenados, a título enunciativo pero no limitativo, en foros, chats, blogs, comentarios, redes sociales o 
		cualesquiera otro medio que permita a terceros publicar contenidos de la forma independiente en la página web http://www.cocowat.com. No obstante y en cumplimiento de lo dispuesto en la LSSI, 
		el titular del sitio web se pone a disposición de todos los usuarios, autoridades y fuerzas de seguridad, y colaborando de forma activa en la retirada o en su caso bloqueo de todos aquellos 
		contenidos que pudieran afectar o contravenir la legislación nacional, o internacional, derechos de terceros o la moral y el orden público. </p>

		<p>En caso de que el usuario considere que existe en el sitio web algún contenido que pudiera ser susceptible de esta clasificación rogamos se ponga en contacto con el titular del sitio web.</p>

		<p>El titular del sitio web no se responsabiliza de las contestaciones que se realicen a través de las distintas direcciones de correo electrónico que aparecen en su sitio web, 
		por lo que en ningún caso podrá derivarse efecto jurídico vinculante de las mismas.</p>

		<h2 className="title">Links o hiperenlaces</h2>
		<p>Los enlaces contenidos en nuestro sitio web pueden dirigir a contenidos web de tercero. El objetivo de dichos enlaces es únicamente facilitarle la búsqueda de los recursos 
		que le puedan interesar a través de Internet. No obstante, dichas páginas no pertenecen al titular del sitio web, ni hace una revisión de sus contenidos y, por ello, el titular 
		del sitio web no asume ninguna responsabilidad por el contenido, informaciones o servicios que pudieran aparecer en dichos sitios, que tendrán exclusivamente carácter informativo 
		y que en ningún caso implican relación alguna entre el titular del sitio web y las personas o entidades titulares de tales contenidos o titulares de otros sitios donde se encuentren. </p>

		<p>El titular del sitio web tampoco puede hacerse responsable del funcionamiento de la página enlazada o de los posibles daños que puedan derivarse del acceso o uso de la misma. Los enlaces 
		al sitio web http://www.cocowat.com deberán respetar las siguientes condiciones:</p>

		<ul>
				<li>El establecimiento del enlace no supondrá ningún tipo de acuerdo, contrato, patrocinio ni recomendación por parte de Anna Ferry Mestres de la página que realiza el enlace.</li>
				<li>La página web en la que se establezca el hiperenlace no contendrá informaciones con contenidos que sean ilícitos, discriminatorios, contrarios a los principios éticos comúnmente 
				aceptados o atenten contra el orden público, así como tampoco contendrá contenidos contrarios a cualesquiera derechos de terceros.</li>
				<li>El titular del sitio web podrá solicitar que se retire un enlace a su web, sin necesidad de alegar causa alguna. En tal caso, la página que haya realizado el enlace deberá
				 proceder a su inmediata supresión, tan pronto como reciba la notificación del titular del sitio web.</li>
				<li>No se responsabiliza de modo alguno ni garantiza la claridad, exactitud, fiabilidad, corrección o moralidad de contenidos o servicios que el establecimiento del hiperenlace pueda ofrecer. 
				El usuario asume bajo su exclusiva responsabilidad las consecuencias, daños o acciones que pudieran derivarse del acceso a la página web del hiperenlace</li>
				<li>La página web en la que se establezca el hiperenlace no podrá contener marca, denominación, logotipo, eslogan u otros signos distintivos pertenecientes a el titular del sitio web 
				excepto aquellos signos que formen parte del mismo hiperenlace</li>
		</ul>

		<h2 className="title">Protección de datos personales</h2>
		<p>Para aquellos casos que se recaben, traten o almacenen datos personales, se hará en conformidad con la Política de Privacidad publicada en el Website y que puede consultar 
		en el pie o footer del sitio web.</p>

		<h2 className="title">Ley aplicable y jurisdicción</h2>
		<p>La ley aplicable en caso de disputa o conflicto e interpretación de los términos que conforman este Aviso Legal, así como cualquier cuestión relacionada con los servicios 
		del presente Portal, será la ley española.</p>

		<p>Para la resolución de cualquier conflicto que pueda surgir con ocasión de la visita al sitio web o del uso de los servicios que en él se puedan ofertar, el titular del sitio web y el 
		Usuario acuerdan someterse a los Jueces y Tribunales de domicilio del Usuario, siempre que el mismo esté situado en territorio español y actúe en condición de consumidor. En caso contrario, 
		la sumisión será a los juzgados y tribunales de la ciudad de Barcelona, España.</p>

    </div>
  </Layout>
)

export default AvisoLegal